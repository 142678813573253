<template>
  <div>
      <div class="header">
          <div class="container">
              <img src="../../assets/images/contrastLogo.png" alt="" class="logo">
              <p class="no">报告编号：<span>ZGZL38289990</span></p>
              <div class="nav">
                  <div :class="navIndex == 1?'active':''" @click="navIndex = 1">文献检测报告全文对照版</div>
                  <div :class="navIndex == 2?'active':''" @click="navIndex = 2">文献检测详细报告</div>
                  <div :class="navIndex == 3?'active':''" @click="navIndex = 3">文献检测原文标注</div>
              </div>
              <div class="headerBtn">
                  <button><img src="../../assets/images/contrastIcon1.png" alt=""><span>再次检测</span></button>
                  <button><img src="../../assets/images/contrastIcon2.png" alt=""><span>版权登记</span></button>
              </div>
          </div>
      </div>

    <div class="container">
        <div class="title">
            <p>文献检测报告全文对照版</p>
            <p><span>检测时间：</span>2024-08-01  16 :30:23</p>
        </div>

        <a-row>
            <a-col class="contentL" :md="24" :lg="16" :xl="16">
                <div class="contentInfo">
                    <div class="contentTitle">基本信息</div>
                    <div class="contentInfoBox">
                        <p>文献题目：<span>财务管理战略转型下的企业财务数字化建设思考</span></p>
                        <p>文献作者：<span>高合萱</span></p>
                        <p>文献类型：<span>学术研究</span></p>
                        <p>检测字符数：<span>8000</span></p>
                        <p>全文总字符数：<span>8000</span></p>
                        <p>重复字符数：<span>1032</span></p>
                        <p>全文总重复率：<span>12.9%</span></p>
                    </div>
                </div>
                <div class="contentDiv">
                    <div class="contentTitle">全文标注</div>
                    <p>1 企业财务数字化建设基于财务管理战略转型下的不足</p>
                    <p>1.1 缺乏对财务数字化建设的足够认识 </p>
                    <p>目前在推进财务数字化建设的进程中，虽然很多财务工作人员意识到建设财务数字化的重要性，但是没有对其进行深入的理解和认识。尽管众多企业已经加强了对财务数字化建设的关注和投入，但传统的财务管理观念所带来的障碍，
                        部分数字化进程仍然停留在数字系统的搭建阶段。正是因为相关人员<span>缺乏对数字化建设的相关知识内容<i>相似度：0.1%</i></span>的了解，使得其在实际组织建设财务数字化的过程中，无法获得应有的效果。
                        也就是说，受到财务数字化建设思想意识差的影响，相关人员无法明确促进各个部门之间财务信息 交流工作的意义所在，致使财务信息在实际传播时，受到了严重阻碍，导致发展战略规划不具备统一性。
                        信息化程度不足，加之各部门之间沟通不畅，致使企业整体的业务处理能力不足。另外，企业各部门之间在传递信息的过程中，因为协同机制不完善，<span>直接影响到了实际的传递效率，并造成后续财务信息存在失真的情况</span>，
                        进一步阻碍了决策效率优化工作的顺利实施。 </p>
                    <p>1.2 缺乏系统性的财务数字化建设 </p>
                    <p>在信息化时代的背景之下，众多企业开始逐步尝试向数字化的财务管理模式进行转型，并在某种程度上获得了一定成果，但也导致了企业间竞争的加剧。目前，很多企业管理者对于企业财务管理方面仍有不了解、不重视等情况发生，从而导致企业财务数字化建设进程缓慢。
                        因此，企业在进行财务数字化建设时，由于缺乏系统化的方法，只能解决某一部门面临的财务管理难题，这进一步影响了财务数字信息系统，导致了系统秩序的混乱。此外，因缺少统一规范的管理方式，致使部分业务数据不能得到有效利用。
                        既给顺利实施相关财务管理工作带来一定阻碍，又加大了财务管理工作的投入成本。另外，在实际建设财务数字化的过程中，其工作缺乏系统性，未对企业整体发展进行综合考量，导致其无法有效地将信息技术应用于日常管理工作当中，致使企业财务管理效率低下。
                        在财务数字化建设的实际工作中，尽管已经投入了一定的资金用于购硬件设备等基础建设，但财务软件方面的投资并没有得到足够的重视，这直接给建设财务数字化工作效率带来了一定的影响。根据相关调查统计可以知道，
                        当前尚有约70%的企业并未对数字化 平台进行建设，进一步影响了企业财务管理策略的转型效果。</p>
                    <p>1.3 缺乏足够的财务管理人才 </p>
                    <p>目前，大部分企业所聘用的财务管理人员，依旧秉持传统的管理思想，且大部分属于核算型人才，其对传统财务工作有着较高的了解和熟练的掌握。但是随着数字化时代的发展，开始有越来越多的新财务管理理念和专业技术出现，
                        相关人员受到传统思想和工作的桎梏，导致企业在实施财务数字化建设的过程中，效率较低。 
                        与此同时，受到传统企业内部管理形式的影响，在对内部财务管理人员进行配置时存在偏差情况。每名财务人员的价值无法最大化地发挥出来，从而给企业财务管理战略转型效果带来影响。其次，企业在推进财务数字化的过程中，
                        往往缺少整体的思考，也没有与财务管理部门和整体管理策略建立足够的联系。<span>同时，在信息化建设过程中，部分企业财务工作人员并没有将信息技术与传统财务 管理进行有机结合 ，从而造成信息共享程度不高，不利于企业的整体管理。</span>
                        这导致财务管理部门始终在企业的独立位置，与其他部门之间的沟通和交流不足，从而对企业的未来发展方向的决策产生了不利的影响。最后，由于企业财务信息化管理存在诸多问题， 
                        导致其无法满足现代化企业的实际需求。而部分企业由于对财务 管理的了解不足，没有给予财务管理人员培训足够的重视，这导致了企业内部专业财务管理人员的数量相对较少。</p>
                    <p>1.4 缺乏完善的财务数据安全风险管控 </p>
                    <p>当前阶段内，我国绝大多数企业尚未构建一个健全的财务数 据安全机制，这导致了大量的财务信息网络在安全性方面存在不 足。
                        随着现阶段建设财务数字化的步伐逐渐加快，外部网络环境在企业财务系统使用时充当着重要角色，也在一定程度上，给财务数据信息带来了风险问题。因此，需要加强企业财务信息安全管理力度，提升其整体安全水平。 
                        再者，尽管有些企业已经为财务数据信息安全配置了相应的保护系统，但由于技术不够成熟，导致该保护系统仍然存在缺陷。</p>
                </div>
            </a-col>
            <a-col class="contentR" :md="24" :lg="8" :xl="8">
                <div class="falgInfo">
                    <div class="contentTitle">片段的不同颜色表示不同的疑似范围</div>
                    <div class="flagList">
                        <p>红色代表除参考文献及可能自引外的其他相似比</p>
                        <p>黄色代表可能自引相似比</p>
                        <p>绿色代表参考文献相似比</p>
                    </div>
                    <div class="flagTip">如果要查看片段详细识别结果，请点击有颜色标识的部分。</div>
                </div>
                <div class="contentFragment1">
                    <div class="contentTitle">原文片段</div>
                    <p>正是因为相关人员<span>缺乏对数字化建设的相关知识内容</span>的了解，使得其在实际组织建设财务数字化的过程中，无法获得应有的效果。</p>
                </div>
                <div class="contentFragment2">
                    <div class="contentTitle">相似片段<span>相似字符数：15</span></div>
                    <p class="Fragment2P">第一，应与企业以往的系统构架相结合，并在其中融入未来创新技术以及外部数字服务，确保企业财务数字化建设符合企业持续发展的战略要求。第二，实际平台体系构建时，<span>缺乏对数字化建设的相关知识内容</span>，应该更加重视企业在其成长过程中形成的关键实体数据，以确保这些数据的商业价值得到最大化的利用。</p>
                    <div class="contentTitle">相似片段来源</div>
                    <div class="FragmentForm">
                        <p>文献题目：财务管理战略转型下的企业财务数字化建设思考</p>
                        <p>文献作者：高合萱</p>
                        <p>来源：《财会探析》</p>
                        <p>类型：学术研究</p>
                        <p>时间：2023-02-23</p>
                        <p>是否引用：<span>否</span></p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
      
      <div class="footer">
          <p>检测报告由中国科技论文在线文献相似性检测系统算法生成</p>
        <p>中文出版物知识产权评价中心</p>
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
        navIndex: 1,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media screen and (min-width: 1px) and (max-width: 1200px){
    .nav,.headerBtn{display: none!important;}
    .contentL{padding-right: 0px!important;}
}
.header{width: 100%;height: 60px;position: fixed;left: 0;top: 0;z-index: 99;width: 100%;background: #102A77;}
.header .container{height: 60px;display: flex;align-items: center;justify-content: space-between;}
.logo{width: 220px;height: 24px;}
.no{margin-left: 12px;font-family: 'AlibabaPuHuiTi, AlibabaPuHuiTi';font-size: 14px;color: #FFFFFF;line-height: 16px;white-space: nowrap;}
.no span{font-family: 'ArialMT';font-size: 14px;color: #FFFFFF;line-height: 16px;}
.nav{display: flex;align-items: center;margin-left: 24px;margin-right: auto;height: 60px;}
.nav div{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #FFFFFF;line-height: 50px;margin-top: 10px;display: block;text-align: center;cursor: pointer;position: relative;background: #102A77;padding: 0 20px;}
.nav div.active{background: #F7F9FA;color: #102A77;border-radius: 12px 12px 0 0;box-shadow: 12px 12px 0 0 #F7F9FA, -12px 12px 0 0 #F7F9FA;z-index: 1;}
.nav div.active::before{content: '';width: 12px;height: 50px;background: #102A77;position: absolute;left: -12px;bottom: 0;border-radius: 0 0 12px 0;}
.nav div.active::after{content: '';width: 12px;height: 50px;background: #102A77;position: absolute;right: -12px;bottom: 0;border-radius: 0 0 0 12px;}
.headerBtn button{width: 100px;height: 28px;background: rgba(76, 108, 203, 0.35);border-radius: 4px;border: none;outline: none;margin-left: 10px;cursor: pointer;}
.headerBtn button img{width: 20px;height: 20px;margin-right: 8px;vertical-align: middle;}
.headerBtn button span{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #FFFFFF;line-height: 28px;display: inline-block;vertical-align: middle;}

.title{height: 70px;line-height: 70px;display: flex;align-items: center;justify-content: space-between;}
.title p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 16px;color: #212B43;line-height: 70px;white-space: nowrap;}
.title p:last-child{font-family: 'AlibabaPuHuiTi, AlibabaPuHuiTi';font-size: 14px;}
.title p span{color: #9095A1;}

.contentL{padding-right: 20px;}

.contentInfo,.contentDiv,.falgInfo,.contentFragment1,.contentFragment2{background: #FFFFFF;margin-bottom: 20px;padding: 30px;}
.contentTitle{font-family: 'SourceHanSansCN, SourceHanSansCN';font-weight: bold;font-size: 16px;color: #212B43;line-height: 24px;margin-bottom: 10px;}

.contentInfoBox p{display: inline-block;width: calc(100% / 3);height: 40px;line-height: 40px;font-family: 'AlibabaPuHuiTi, AlibabaPuHuiTi';font-size: 14px;color: #9095A1;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.contentInfoBox p:first-child{width: 100%;}
.contentInfoBox p span{font-family: 'ArialMT';color: #18223A;}
.contentInfoBox p:nth-child(5) span,.contentInfoBox p:nth-child(6) span,.contentInfoBox p:nth-child(7) span{color: #EF1B1B;}
.contentInfoBox p:nth-child(7) span{font-family: Arial, Arial;font-weight: 600;}

.contentDiv p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #333333;line-height: 24px;}
.contentDiv p:nth-child(4) span{color: #EF1B1B;position: relative;}
.contentDiv p:nth-child(8) span{color: #FEB92B;}
.contentDiv p:nth-child(4) span i{font-style: normal;display: block;height: 32px;padding: 0 10px;position: absolute;bottom: 24px;left: 50px;background: #EC563B;font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #FFFFFF;
    line-height: 32px;border-radius: 6px;}
.contentDiv p:nth-child(4) span i::after{content: '';position: absolute;top: 32px;left: 10px;width: 0;height: 0;border: 5px solid transparent;border-top: 5px solid #EC563B;}

.flagList p{margin: 8px 0;margin-left: 20px;height: 20px;line-height: 20px;font-family: 'AlibabaPuHuiTi, AlibabaPuHuiTi';font-size: 12px;padding: 0 8px;display: inline-block;position: relative;border-radius: 2px;}
.flagList p:nth-child(1){background: #FEECED;color: #E9777A;}
.flagList p:nth-child(1)::before{content: '';width: 12px;height: 12px;background: #E9777A;border-radius: 50%;position: absolute;left: -20px;top: 4px;}
.flagList p:nth-child(2){background: #FEF6EC;color: #FF9300;}
.flagList p:nth-child(2)::before{content: '';width: 12px;height: 12px;background: #FF9300;border-radius: 50%;position: absolute;left: -20px;top: 4px;}
.flagList p:nth-child(3){background: #E0FAF1;color: #4CD685;}
.flagList p:nth-child(3)::before{content: '';width: 12px;height: 12px;background: #4CD685;border-radius: 50%;position: absolute;left: -20px;top: 4px;}
.flagTip{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 12px;color: #9095A1;line-height: 14px;margin: 8px 0;}

.contentFragment1{border-bottom: 1px solid #C6CDD3;margin-bottom: 0;}
.contentFragment1 p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #333333;line-height: 24px;}
.contentFragment1 p span{color: #EF1B1B;}

.contentFragment2 .contentTitle{position: relative;}
.contentFragment2 .contentTitle span{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 12px;color: #2681FD;display: block;height: 16px;line-height: 16px;padding: 0 6px;position: absolute;right: 0;top: 4px;background: #EBF5FF;border-radius: 2px;}
.contentFragment2 .Fragment2P{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #333333;line-height: 24px;margin-bottom: 30px;}
.contentFragment2 .Fragment2P span{color: #EF1B1B;}
.FragmentForm{background: #F6F7F9;padding: 12px;}
.FragmentForm p{line-height: 30px;font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: #8492A1;}
.FragmentForm p span{color: #EF1B1B;}

.footer{width: 100%;height: 60px;text-align: center;padding: 6px 0;margin: 20px 0;}
.footer p{font-family: 'SourceHanSansCN, SourceHanSansCN';font-size: 14px;color: rgba(102,102,102,0.85);line-height: 24px;}
</style>
